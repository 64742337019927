// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          background: "#f6f2ff",
          surface: "#f6f2ff",
          primary: "#890620",
          secondary: "#b64e68",
          info: "#dcccff",
          tertiary: "#1C6E8C",
          black: "#000000",
        },
      },
    },
  },
});
