<template>
  <v-app>
    <v-app-bar
      app
      class="bg-primary"
      style="border-bottom: 2px solid #2c0703 !important"
    >
      <v-btn to="/">Home</v-btn>
      <v-btn disabled>About</v-btn>
      <v-btn disabled>Request Meeting</v-btn>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",

  data() {
    return {
      //
    };
  },
});
</script>

<style scoped>
.back {
  background: url("../public/img/background/back.jpg");
  background-size: cover;
}
</style>
