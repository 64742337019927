<template>
  <v-container>
    <v-row>
      <v-col xl="3" lg="3" md="3" sm="12" xs="12">
        <v-img
          width="625"
          aspect-ratio="16/9"
          class="ma-2 pa-2"
          src="../../public/img/logo/logo.png"
        ></v-img>
      </v-col>
      <v-spacer></v-spacer>
      <v-col xl="7" lg="7" md="7" sm="12" xs="12">
        <v-card
          :height="$vuetify.display.smAndDown ?  undefined : '720'"
          class="bg-background ma-2 pa-6 scroll"
          variant="flat"
        >
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12" xs="12">
              <v-card variant="flat" height="225" class="bg-background">
                <v-card-title
                  >With 2 sales locations to serve you.</v-card-title
                >
                <v-card-text>
                  Legacy Art Stones Corp has a combined 20 + years in the
                  countertop and construction industry. With a helping hand in
                  design and color matching, we can help you make your
                  remodeling dream project reality. We can also help with
                  cabinet designs, backsplash ideas and installation, and LVP
                  flooring and installation. Legacy Art Stones Corp can also
                  preform the demolition.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" xs="12" class="blur">
              <v-img
                src="../../public/img/projects/blurbp2.jpg"
                class="rounded-shaped"
                height="350"
              ></v-img>
            </v-col>
            <v-col cols="12" class="back"></v-col>
            <v-col cols="12" class="bg-secondary">
              <v-sheet class="bg-secondary ma-0 pa-0 text-center">
                <p>
                  <v-icon>mdi-map-marker-check-outline</v-icon> 3674 Express Dr.
                  STE 5 Shallotte NC, 28470
                </p>
                <p>
                  <v-icon> mdi-satellite-variant </v-icon> 1583 American Way
                  Myrtle Beach SC, 29577
                </p>
                <p>
                  <v-icon>mdi-clock-digital</v-icon> Mon - Fri 9am - 5pm |
                  Saturday: Apt Only | Sunday: Closed
                </p>
                <p><v-icon>mdi-phone-classic</v-icon> (910) 712-3927</p>
                <p><v-icon>mdi-email</v-icon> sales@legacyartstones.com</p>
              </v-sheet>
            </v-col>
            <v-col cols="12" class="back"></v-col>
            <v-col cols="12" class="pa-0 ma-0">
              <v-carousel :continuous="true" class="pa-0 ma-0">
                <v-carousel-item
                  src="../../public/img/projects/amber3.jpg"
                  cover
                ></v-carousel-item>
                <v-carousel-item
                  src="../../public/img/projects/amber1.jpg"
                  cover
                ></v-carousel-item>
                <v-carousel-item
                  src="../../public/img/projects/amber2.jpg"
                  cover
                ></v-carousel-item>
                <v-carousel-item
                  src="../../public/img/projects/amber4.jpg"
                  cover
                ></v-carousel-item>
                <v-carousel-item
                  src="../../public/img/projects/daniel1.jpg"
                  cover
                ></v-carousel-item>
                <v-carousel-item
                  src="../../public/img/projects/daniel2.jpg"
                  cover
                ></v-carousel-item>
                <v-carousel-item
                  src="../../public/img/projects/daniel3.jpg"
                  cover
                ></v-carousel-item>
                <v-carousel-item
                  src="../../public/img/projects/daniel4.jpg"
                  cover
                ></v-carousel-item>
                <v-carousel-item
                  src="../../public/img/projects/innes1.jpg"
                  cover
                ></v-carousel-item>
                <v-carousel-item
                  src="../../public/img/projects/innes3.jpg"
                  cover
                ></v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col cols="12" class="back"></v-col>
            <v-col cols="12" class="bg-secondary">
              <v-card variant="flat" class="pa-0 ma-0 bg-secondary">
                <v-card-title class="text-right text-h4">Our Team</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                      <p>
                        <v-img
                          class="rounded-circle"
                          height="200"
                          src="../../public/img/team/pres.jpg"
                        ></v-img>
                      </p>
                      <p class="text-h6 text-center">President Barbara Pugh</p>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                      <p>
                        <v-img
                          class="rounded-circle"
                          height="200"
                          src="../../public/img/team/admin.jpg"
                        ></v-img>
                      </p>
                      <p class="text-h6 text-center pl-">Admin Lacey Lee</p>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                      <p>
                        <v-img
                          class="rounded-circle"
                          height="200"
                          src="../../public/img/team/head_of_tech.jpg"
                        ></v-img>
                      </p>
                      <p class="text-h6 text-center pl-">Tech. Randall Lee</p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="$vuetify.display.mdAndUp" cols="12" class="back"></v-col>
            <v-col v-if="$vuetify.display.mdAndUp" cols="12">
              <v-card variant="flat">
                <v-card-title>Experienced In</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                      <v-img
                        :width="$vuetify.display.smAndDown ? '400' : undefined"
                        height="200"
                        src="../../public/img/products/apuane_statuary_crystal.jpg"
                      ></v-img>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                      <v-img
                        :width="$vuetify.display.smAndDown ? '400' : undefined"
                        height="200"
                        src="../../public/img/products/absolute_black_leathered_granite.jpg"
                      ></v-img>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                      <v-img
                        :width="$vuetify.display.smAndDown ? '400' : undefined"
                        height="200"
                        src="../../public/img/products/calacatta_riga_select_quartz.jpg"
                      ></v-img>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                      <v-img
                        :width="$vuetify.display.smAndDown ? '400' : undefined"
                        height="200"
                        src="../../public/img/products/fantasy_brown_marble.jpg"
                      ></v-img>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                      <v-img
                        :width="$vuetify.display.smAndDown ? '400' : undefined"
                        height="200"
                        src="../../public/img/products/jeriba_blue_quartzite.jpg"
                      ></v-img>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                      <v-img
                        :width="$vuetify.display.smAndDown ? '400' : undefined"
                        :height="$vuetify.display.xs ? '500' : '200'"
                        src="../../public/img/products/salinas_white_granite.jpg"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="back"></v-col>
            <v-col cols="12" class="bg-secondary">
              <v-card variant="flat" class="bg-secondary">
                <v-card-title>Services</v-card-title>
                <v-card-text class="text-center">
                  <li
                    v-for="item in [
                      'Kitchen and vanity countertops',
                      'Fireplace surround, mantel, and hearth',
                      'Tub surround',
                      'Shower walls and floors',
                      'AND so much more',
                    ]"
                    :key="item"
                  >
                    {{ item }}
                  </li>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="back"></v-col>
            <v-col cols="12" class="bg-secondary">
              <v-card variant="flat" class="bg-secondary">
                <v-card-title>Our Suppliers</v-card-title>
                <v-card-text class="text-center">
                  <v-row>
                    <v-col cols="12">
                      <a class="text-center" href="https://www.msisurfaces.com/"
                        ><v-img
                          width="300"
                          src="https://cdn.msisurfaces.com/images/miscellaneous/linklogo-msi1.jpg"
                      /></a>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class About extends Vue {}
</script>

<style scoped>
li {
  list-style: none;
}
.scroll {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.back {
  background: url("../../public/img/background/back.jpg");
  background-size: cover;
}
</style>
