import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        app: "",
        class: "bg-primary",
        style: {"border-bottom":"2px solid #2c0703 !important"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, { to: "/" }, {
            default: _withCtx(() => [
              _createTextVNode("Home")
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, { disabled: "" }, {
            default: _withCtx(() => [
              _createTextVNode("About")
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, { disabled: "" }, {
            default: _withCtx(() => [
              _createTextVNode("Request Meeting")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}