<template>
  <div class="flex">
    <p class="flex-1-0-100 ma-2 pa-2 text-h1 text-center text-inAppText">
      Legacy Art Stones is Under Construction
    </p>
    <v-img
      class="flex-1-1-100"
      height="400"
      aspect-ratio="16/9"
      src="../../public/img/construction/caution-tape.png"
    >
    </v-img>
    <p class="flex-1-2-100 ma-2 pa-2 text-h3 text-center">
      Please reach out to sales@legacyartstones.com for inquiries.
    </p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Construction extends Vue {}
</script>
